import { Feedback } from '~/components/Discours/Feedback'
import { StaticPage } from '~/components/Views/StaticPageView'
import { Modal } from '~/components/_shared/Modal'
import { Opener } from '~/components/_shared/Modal/Opener'
import { Newsletter } from '~/components/_shared/Newsletter'

export default () => (
  <StaticPage
    title={'Discours Manifest'}
    desc={
      'Manifest of samizdat: principles and mission of \
      an open magazine with a horizontal editorial board'
    }
  >
    <Modal variant="wide" name="feedback">
      <Feedback />
    </Modal>
    <Modal variant="wide" name="subscribe">
      <Newsletter />
    </Modal>

    <p>
      Дискурс — независимый художественно-аналитический журнал с горизонтальной редакцией, основанный на
      принципах свободы слова, прямой демократии и совместного редактирования. Дискурс создаётся открытым
      медиасообществом ученых, журналистов, музыкантов, писателей, предпринимателей, философов, инженеров,
      художников и специалистов со всего мира, объединившихся, чтобы вместе делать общий журнал и объяснять
      с разных точек зрения мозаичную картину современности.
    </p>
    <p>
      Мы пишем о культуре, науке и обществе, рассказываем о новых идеях и современном искусстве, публикуем
      статьи, исследования, репортажи, интервью людей, чью прямую речь стоит услышать, и работы художников
      из разных стран — от фильмов и музыки до живописи и фотографии. Помогая друг другу делать публикации
      качественнее и общим голосованием выбирая лучшие материалы для журнала, мы создаём новую
      горизонтальную журналистику, чтобы честно рассказывать о важном и интересном.
    </p>
    <p>
      Редакция Дискурса открыта для всех: у нас нет цензуры, запретных тем и идеологических рамок. Каждый
      может <a href="/edit/new">прислать материал</a> в журнал и{' '}
      <a href="/guide">присоединиться к редакции</a>. Предоставляя трибуну для независимой журналистики и
      художественных проектов, мы помогаем людям рассказывать свои истории так, чтобы они были услышаны. Мы
      убеждены: чем больше голосов будет звучать на Дискурсе, тем громче в полифонии мнений будет слышна
      истина.
    </p>

    <h2 class="h2" id="participation">
      <span class="wrapped">Как участвовать в самиздате</span>
    </h2>

    <p>
      Дискурс создается <a href="/guide">открытым сообществом</a> энтузиастов новой независимой
      журналистики. Участвовать в открытой редакции и помогать журналу можно следующими способами:
    </p>
    <details open>
      <summary>
        <h3 id="contribute">Предлагать материалы</h3>
      </summary>
      <p>
        <a href="/edit/new">Создавайте</a> свои статьи и художественные работы — лучшие из них будут
        опубликованы в журнале. Дискурс — некоммерческое издание, авторы публикуются в журнале на
        общественных началах, получая при этом <a href="/edit/new?collab=true">поддержку</a> редакции, право
        голоса, множество других возможностей и читателей по всему миру.
      </p>
    </details>

    <details>
      <summary>
        <h3 id="donate">Поддерживать проект</h3>
      </summary>
      <p>
        Дискурс существует на пожертвования читателей. Если вам нравится журнал, пожалуйста,{' '}
        <a href="/support">поддержите</a> нашу работу. Ваши пожертвования пойдут на выпуск новых материалов,
        оплату серверов, труда программистов, дизайнеров и редакторов.
      </p>
    </details>

    <details>
      <summary>
        <h3 id="cooperation">Сотрудничать с журналом</h3>
      </summary>
      <p>
        Мы всегда открыты для сотрудничества и рады единомышленникам. Если вы хотите помогать журналу с
        редактурой, корректурой, иллюстрациями, переводами, версткой, подкастами, мероприятиями,
        фандрайзингом или как-то ещё — скорее пишите нам на
        <a href="mailto:welcome@discours.io">welcome@discours.io</a>.
      </p>
      <p>
        Если вы представляете некоммерческую организацию и хотите сделать с нами совместный проект, получить
        информационную поддержку или предложить другую форму сотрудничества —{' '}
        <a href="mailto:welcome@discours.io">пишите</a>.
      </p>
      <p>
        Если вы разработчик и хотите помогать с развитием сайта Дискурса,{' '}
        <a href="mailto:services@discours.io">присоединяйтесь к IT-команде самиздата</a>. Открытый код
        платформы для независимой журналистики, а также всех наших спецпроектов и медиаинструментов
        находится <a href="https://github.com/Discours">в свободном доступе на GitHub</a>.
      </p>
    </details>

    <details>
      <summary>
        <h3 id="follow">Как еще можно помочь</h3>
      </summary>
      <p>
        Советуйте Дискурс друзьям и знакомым. Обсуждайте и распространяйте наши публикации — все материалы
        открытой редакции можно читать и перепечатывать бесплатно. Подпишитесь на самиздат{' '}
        <a href="https://vk.com/discoursio">ВКонтакте</a>, в
        <a href="https://facebook.com/discoursio">Фейсбуке</a> и в
        <a href="https://t.me/discoursio">Телеграме</a>, а также на
        <Opener name="subscribe">рассылку лучших материалов</Opener>, чтобы не пропустить ничего
        интересного.
      </p>
      <p>
        <a href="https://forms.gle/9UnHBAz9Q3tjH5dAA">Рассказывайте о впечатлениях</a> от материалов
        открытой редакции, <Opener name="feedback">делитесь идеями</Opener>, интересными темами, о которых
        хотели бы узнать больше, и историями, которые нужно рассказать.
      </p>
    </details>

    <h2 class="h2" id="connection">
      <span class="wrapped">Будем на связи</span>
    </h2>

    <p>
      Если вы хотите предложить материал, сотрудничать, рассказать о проблеме, которую нужно осветить,
      сообщить об ошибке или баге, что-то обсудить, уточнить или посоветовать, пожалуйста,{' '}
      <Opener name="feedback">напишите нам здесь</Opener> или на почту{' '}
      <a href="mailto:welcome@discours.io">welcome@discours.io</a>. Мы обязательно ответим и постараемся
      реализовать все хорошие задумки.
    </p>
  </StaticPage>
)
